import { InjectionToken, Type } from '@angular/core';

export const DIALOG_COMPONENT = new InjectionToken<Type<Dialog>>('DIALOG_COMPONENT');
export const MODAL_OPTIONS = new InjectionToken<ModalOptions<any>>('MODAL_OPTIONS');

/**
 * Any component intended to be used with the ModalService.fromComponent() method must implement
 * this interface.
 */
export interface Dialog<R = any> {
    /**
     * Function to be invoked in order to close the dialog when the action is complete.
     * The Observable returned from the .fromComponent() method will emit the value passed
     * to this method and then complete.
     */
    resolveWith: (result?: R) => void;
}

/**
 * Options to configure the behaviour of the modal.
 */
export interface ModalOptions<T> {
    /** Sets the width of the dialog */
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
    /**
     * When true, the "x" icon is shown
     * and clicking it or the mask will close the dialog
     */
    closable?: boolean;
    /**
     * Values to be passed directly to the component.
     */
    locals?: Partial<T>;
    /**
     * If true, will automatically focus the first <input> element with the
     * `autofocus` attribute, or else the first <input> element in the modal body.
     * Defaults to true.
     */
    focusFirstInput?: boolean;
}
