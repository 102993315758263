import { AfterViewInit, Component, Inject, TemplateRef, Type, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgClass, NgTemplateOutlet, NgIf, AsyncPipe } from '@angular/common';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
    Dialog,
    DIALOG_COMPONENT,
    MODAL_OPTIONS,
    ModalOptions,
} from '../../../core/providers/modal/modal-types';

import { DialogButtonsDirective } from './dialog-buttons.directive';
import { DialogComponentOutletComponent as DialogComponentOutletComponent_1 } from './dialog-component-outlet.component';

/**
 * This component should only be instatiated dynamically by the ModalService. It should not be used
 * directly in templates. See {@link ModalService.fromComponent} method for more detail.
 */
@Component({
    selector: 'vsf-modal-dialog',
    templateUrl: './modal-dialog.component.html',
    styleUrls: ['./modal-dialog.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        NgIf,
        FontAwesomeModule,
        DialogComponentOutletComponent_1,
        AsyncPipe,
    ],
})
export class ModalDialogComponent<T extends Dialog<any>> implements AfterViewInit {
    closeModal: (result?: any) => void;
    titleTemplateRef$ = new Subject<TemplateRef<any>>();
    buttonsTemplateRef$ = new Subject<TemplateRef<any>>();
    times = faTimes;

    constructor(
        private viewContainerRef: ViewContainerRef,
        @Inject(DIALOG_COMPONENT) public childComponentType: Type<T>,
        @Inject(MODAL_OPTIONS) public options?: ModalOptions<T>,
    ) {}

    ngAfterViewInit() {
        const { nativeElement } = this.viewContainerRef.element;
        if (nativeElement && this.options?.focusFirstInput !== false) {
            const firstAutofocusInput = nativeElement.querySelector('input[autofocus]:not([disabled])');
            if (firstAutofocusInput) {
                firstAutofocusInput.focus();
            } else {
                nativeElement.querySelector('input:not([disabled])')?.focus();
            }
        }
    }

    /**
     * This callback is invoked when the childComponentType is instantiated in the
     * template by the {@link DialogComponentOutletComponent}.
     * Once we have the instance, we can set the resolveWith function and any
     * locals which were specified in the config.
     */
    onCreate(componentInstance: T) {
        componentInstance.resolveWith = (result?: any) => {
            this.closeModal(result);
        };
        if (this.options && this.options.locals) {
            // tslint:disable-next-line
            for (const key in this.options.locals) {
                componentInstance[key] = this.options.locals[key] as T[Extract<keyof T, string>];
            }
        }
    }

    /**
     * This should be called by the {@link DialogTitleDirective} only
     */
    registerTitleTemplate(titleTemplateRef: TemplateRef<any>) {
        this.titleTemplateRef$.next(titleTemplateRef);
    }

    /**
     * This should be called by the {@link DialogButtonsDirective} only
     */
    registerButtonsTemplate(buttonsTemplateRef: TemplateRef<any>) {
        this.buttonsTemplateRef$.next(buttonsTemplateRef);
    }
}
